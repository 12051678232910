<template>
    <div class="float" style="margin-top: 3.5cm;">
        <div class="row">
            <div class="col-md-12 border-bottom">
                <b-button-toolbar aria-label="Toolbar with button groups and input groups">
                    <b-button-group size="sm" class="mr-1">
                        <b-button class="mr-1" @click="menu = 1"
                            :variant="menu === 1 ? 'success' : 'outline-primary'">Profile</b-button>
                        <b-button @click="menu = 2"
                            :variant="menu === 2 ? 'success' : 'outline-primary'">Transaksi</b-button>
                    </b-button-group>
                </b-button-toolbar>
            </div>
            <!-- Para: {{ para }} -->
            <div class="col-md-12" v-show="menu == '1'">
                <b-form @submit="onSubmit">
                    <br>
                    Nama:
                    <b-form-input v-model="form.nama" size="sm" required />
                    Alamat:
                    <b-form-textarea v-model="form.alamat" size="sm" required />
                    Kota:
                    <b-form-input v-model="form.kota" size="sm" required />
                    No.HP:
                    <b-form-input v-model="form.nohp" size="sm" required />
                    <b-button size="sm" class="mt-2 col-4" type="submit">{{ isnew ? 'Save' : 'Update' }}</b-button>
                    <i class="bi bi-plus-circle ml-2 mt-3" @click="reset"></i>
                </b-form>
                <br>
                <i class="bi bi-arrow-clockwise" @click="getdata" />
                <b-table :items="data" small size="sm" responsive :fields="['id', 'nama', 'alamat', 'nohp']"
                    @row-clicked="dataselected" style="font-size: 12px" :tbody-tr-class="rowClass" />
            </div>
            <div class="col-md-12" v-show="menu == '2'">
                <br>
                Transaksi an. <b>{{ this.form.nama }} [{{ form2.id }}]</b>
                <b-form @submit="onSubmit2">
                    <br>
                    Tanggal:
                    <b-form-input v-model="form2.tanggalbeli" type="date" size="sm" required
                        @change="convertToHijri()" />
                    <input type="text" v-model="form2.hijriDate" readonly /><br>
                    Jenis:
                    <b-form-select v-model="form2.jenisdinar" :options="['1/4 Dinar', '1/2 Dinar', '1 Dinar', '2 Dinar', '3 Dinar', '4 Dinar', '5 Dinar', '7 Dinar', '10 Dinar', '16 Dinar', '20 Dinar', '0.025 Gram', '0.05 Gram', '0.1Gram', '0.2 Gram', '0.5 Gram', '1 Gram', '2 Gram', '3 Gram', '5 Gram',
                    ]" size="sm" required />
                    <div class="row">
                        <div class="col">
                            jumlah:
                            <b-form-input v-model="form2.jml" size="sm" type="number" required class="text-right" />
                        </div>
                        <div class="col">
                            Harga Beli:
                            <money v-model="form2.hargabeli" v-bind="money" class="form-control form-control-sm"
                                style="text-align: right" />
                        </div>

                    </div>
                    <b-button size="sm" class="mt-2 col-4" type="submit">{{ isnew2 ? 'Save' : 'Update' }}</b-button>
                    <i class="bi bi-plus-circle ml-2 mt-3" @click="reset2"></i>
                </b-form>
                <i class="bi bi-arrow-clockwise" @click="getdata2" />
                <b-table :items="data2" small size="sm" responsive
                    :fields="['tanggalbeli', 'jenisdinar', 'jml', 'hargabeli', 'tglinput']" @row-clicked="dataselected2"
                    style="font-size: 12px" :tbody-tr-class="rowClass2">
                    <template #head(hargabeli)>
                        <div class="text-right">HargaBeli</div>
                    </template>
                    <template #cell(hargabeli)="row">
                        <div class="text-right">{{ $func.NumberFormat(row.item.hargabeli) }}</div>
                    </template>
                    <template #cell(tglinput)="row">
                        {{ row.item.tglinput.substr(0, 10) }}<b-icon-trash @click="deleterow(row.item)" />
                    </template>
                </b-table>
            </div>

        </div>

    </div>
</template>

<script>
import axios from "axios";
import moment from 'moment-hijri';
export default {
    name: "Produksi",
    components: {

    },

    data() {
        return {
            data: [],
            data2: [],
            busy: false,
            tgl: '',
            menu: '1',
            para: this.$route.params.id,
            isnew: true,
            isnew2: true,
            select: '',
            select2: '',
            form: {
                id: '',
                nama: '',
                alamat: '',
                kota: '',
                nohp: ''
            },
            form2: {
                tanggalbeli: '',
                hijriDate: '',
                tglinput: '',
                jenisdinar: '',
                jml: '',
                hargabeli: 0,
                id: '',
            },
            money: {
                decimal: ".",
                thousands: ",",
                prefix: "",
                suffix: "",
                precision: 0,
                masked: false,
            },


        };
    },
    mounted() {
        this.tgl = this.$func.tglhariini().slice(0, 7);
        this.form2.tanggalbeli = this.$func.tglhariini();
        this.menu = 1
        moment.locale('id');
        const isAuthenticated = localStorage.getItem('authToken');
        if (this.para != isAuthenticated) {
            this.$router.push({ path: "/" })
            console.log('NOtvalid')
        } else {
            this.$globalData.TokenOK = 1
            //  $globalData.UserId
            this.$globalData.UserId = localStorage.getItem('userid')
        }

    },
    watch: {
        "$route.params.id": function (value) {
            console.log("value ID", value)
            this.para = value;
        }
    },
    methods: {
        deleterow(item) {
            console.log("delte row", item.kodeuniq)
            this.$bvModal
                .msgBoxConfirm("Yakin untuk di Simpan ?", {
                    title: "Please Confirm",
                    size: "sm",
                    buttonSize: "sm",
                    okTitle: "YES",
                    cancelTitle: "NO",
                    noCloseOnBackdrop: true,
                })
                .then((value) => {
                    if (value) {
                        axios
                            .get(this.$appUrl + "deletetrans.php?no=" + item.kodeuniq)
                            .then((rs) => {
                                console.log(rs)
                                this.getdata2();
                            })
                    }
                })

        },
        convertToHijri() {
            if (this.form2.tanggalbeli) {
                console.log("grego", this.form2.tanggalbeli)
                // this.form2.hijriDate = moment(this.form2.tanggalbeli, 'YYYY-MM-DD').format('iYYYY/iMM/iDD');
                this.form2.hijriDate = moment(this.form2.tanggalbeli, 'YYYY-MM-DD').format('iYYYY iMMMM iDD');
                console.log("hijri", this.form2.hijriDate)

            }
        },
        reset() {
            this.isnew = true
            this.form = { id: "", nama: "", alamat: "", kota: '', nohp: '' };
            console.log('form', this.form)
        },
        reset2() {
            this.isnew2 = true

            this.form2 = { tanggalbeli: "", hijriDate: "", tglinput: '', jenisdinar: '', jml: 0, hargabli: 0, id: this.form.id };
            console.log('form', this.form)
        },
        getdata() {
            axios
                .post(this.$appUrl + "getdata.php")
                .then((res) => {
                    this.data = res.data;
                    console.log(res.data);
                });

        },
        getdata2() {
            axios
                .post(this.$appUrl + "getdata2.php?id=" + this.form2.id)
                .then((res) => {
                    this.data2 = res.data;
                });

        },
        onSubmit(event) {
            event.preventDefault()
            // alert(JSON.stringify(this.form))
            axios
                .post(this.$appUrl + "saveprofile.php?isnew=" + this.isnew, this.form)
                .then((res) => {
                    console.log(res.data);


                });
        },
        onSubmit2(event) {
            event.preventDefault()
            // alert(JSON.stringify(this.form2))
            axios
                .post(this.$appUrl + "savetransaksi.php?isnew=" + this.isnew2, this.form2)
                .then((res) => {
                    console.log(res.data);


                });
        },
        dataselected(item) {
            this.select = item.id
            this.form2.id = item.id
            this.form = item
            this.isnew = false
            this.getdata2()
        },
        dataselected2(item) {
            this.form2 = item
            this.isnew2 = false
            this.select2 = item.kodeuniq
            this.form2.id = this.form.id
            console.log("select2", this.form2)
        },
        rowClass(item) {
            // return item.cabang.slice(0, 5) === "TOTAL" ? 'table-danger' : '';
            if (item.id == this.select) {
                return ['table-info', 'font-weight-bold'];
            }

            return '';
        },
        rowClass2(item) {
            // return item.cabang.slice(0, 5) === "TOTAL" ? 'table-danger' : '';
            console.log("select2", item)
            if (item.kodeuniq == this.select2) {
                return ['table-info', 'font-weight-bold'];
            }

            return '';
        },
        getdetail(item, jenis) {
            // alert(item.cabang)
            this.jenis = jenis
            console.log(item, jenis)
            this.detail = []
            this.busy2 = true

            this.cabang = item;
            axios
                .get(this.$appUrl + "getdetail.php?bulan=" + this.tgl + "&cabang=" + item + "&jenis=" + jenis)
                .then((res) => {
                    this.detail = res.data
                    this.busy2 = false


                });
            this.$bvModal.show('bv-modal-example')
        },

    },
};
</script>

<style></style>
