<template>

   <div class=" d-flex flex-column vh-100 m-0 text-center">
      <!-- Div Atas dengan Background Biru -->
      <div class="bg-primary " style="height: 40%;"></div>

      <!-- Div Bawah dengan Background Putih -->
      <div class="bg-white" style="height: 10px;"></div>
      <div class="container text-center" style="position:absolute">
         <br />
         <br />
         <div class="text-white text-center">
            <p style="font-size: 30px;">DINAR KR</p>
            <p style="font-style: italic;">Aplikasi untuk Administrasi DINAR</p>
         </div>
         <br />
         <!-- <br />
         <br /> -->
         <br />
         <div class="container">
            <b-card class="container px-0 mt-4 mb-4 mr-4 shadow" header="Login Page">
               <b-form>
                  <b-input-group size="sm">
                     <b-form-input v-model="$globalData.UserId" placeholder="User id" />
                     <b-input-group-append is-text>
                        <b-icon-person-check />
                     </b-input-group-append>
                  </b-input-group>
                  <b-input-group size="sm" class="mt-2">
                     <b-form-input v-model="password" :type="passwordtype ? 'password' : 'text'"
                        placeholder="password" />
                     <b-input-group-append is-text>
                        <b-icon-key @click="passwordtype = !passwordtype" />
                     </b-input-group-append>
                  </b-input-group>
               </b-form>
               <b-button class="float-right mt-3 col-6" block @click="gologin"><b-icon-arrow-right />Login</b-button>
            </b-card>
         </div>
         <!-- {{ $globalData.TokenOK == 1 ? "Login Sukses ... " : "Login Gagal.." }} -->
      </div>
      <!-- </div> -->
   </div>
</template>

<script>
import axios from "axios";

export default {
   name: "Home",
   components: {
      //  Navbar,
   },
   data() {
      return {
         password: "",
         ketlogin: "",
         passwordtype: true,
         bgurl: this.$appUrl + "bg.jpg",
      };
   },
   methods: {
      gologin() {
         axios
            .post(this.$appUrl + "login.php", {
               username: this.$globalData.UserId,
               password: this.password,
            })
            .then((res) => {
               if (res.data == 'sukses') {
                  this.$globalData.TokenOK = 1;
                  localStorage.setItem('userid', this.$globalData.UserId)
                  let kode = Date.now() + Math.floor(Math.random() * 1000);
                  localStorage.setItem('authToken', kode);
                  this.$router.push({ path: "/produksi/" + kode });

                  // this.$router.push({ path: "/inward" });
               } else {
                  this.$globalData.TokenOK = 0;
                  this.$bvModal.msgBoxOk("User & Password tidak sesuai ...", {
                     centered: true,
                  });
               }
            });
      },
   },
};
</script>

<style>
.flex-fill {
   height: 50%;
}
</style>
