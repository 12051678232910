<template>
    <div id="app">

        <!-- <Navbarform v-show="false" /> -->
        <div :class="$globalData.TokenOK == 1 ? 'container-fluid' : 'fluid'" v-show="true">
            <div :class="$globalData.TokenOK == 1 ? 'fixed-top bg-white text-left' : 'text-center'">
                <img src="./assets/logo.jpg" width="25.5%" class="mr-1 mt-3" bg-white />

                <div class="div  mt-4 mr-1 float-right" v-show="$globalData.TokenOK == 1">
                    <span style="font-size: 12px;">Userid:{{ $globalData.UserId }}
                    </span>
                    <i class="bi bi-box-arrow-right" @click="logout"></i>
                </div>
                <hr v-show="$globalData.TokenOK == 1" />
            </div>

            <div>
                <router-view />
            </div>
            <Footer v-show="$globalData.TokenOK == 1" />
        </div>
    </div>
</template>

<script>
import Footer from "./components/footer.vue";
export default {
    name: "App",
    components: {
        Footer
    },
    //      UserId: "",
    //   LoginOK: '',
    //   TokenOK: 0,
    mounted() {
        document.title = "Dinar KR";
        this.$globalData.userId =
            localStorage.getItem("userid") === null
                ? ""
                : localStorage.getItem("userid");
    },
    methods: {
        logout() {
            this.$globalData.TokenOK = 0;
            this.$router.push({ path: "/" });
        }
    }
};
</script>

<style>
body {
    margin: 0 !important;
    padding: 0 !important;
}
</style>
